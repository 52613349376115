import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IP } from '../constants/network';

const ConfirmAccountDeletion = () => {
    const { uid, token } = useParams();
    const [status, setStatus] = useState('Processing...');

    useEffect(() => {
        // Call the backend API to confirm account deletion
        fetch(`${IP}api/users/confirm_account_deletion/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ uid: uid, token: token }),
        })
            .then(response => response.json())
            .then(data => {
                setStatus(data.message || 'Account deletion confirmed.');
            })
            .catch(error => {
                setStatus('An error occurred. Please try again.');
            });
    }, [uid, token]);

    return (
        <div>
            <h2>Account Deletion Confirmation</h2>
            <p>{status}</p>
        </div>
    );
};

export default ConfirmAccountDeletion;
