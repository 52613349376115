import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { slides } from './SlidesDescription';

export default function Slide() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [animationPlayState, setAnimationPlayState] = useState('running');

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        fade: false,
        beforeChange: () => setAnimationPlayState('paused'),
        afterChange: (newIndex) => {
            setCurrentSlide(newIndex);
            setAnimationPlayState('running');
        },
    };

    return (
        <div className="carousel-container">
            <div className="dot-container">
                {slides.map((_, index) => (
                    <span key={index} className={`dot ${index === currentSlide ? 'active' : ''}`}></span>
                ))}
            </div>
            <Slider {...settings}>
                {slides.map((slide, index) => (
                    <div key={index} className="slide">
                        <div className="iphone-container">
                            <img
                                src={require('../assets/iphone-frame.png')}
                                alt='iPhone Frame'
                                className='iphone-frame'
                            />
                            <img
                                src={slide.image}
                                alt={`${index + 1}`}
                                className="carousel-image"
                            />
                        </div>
                        <h3
                            className="carousel-title"
                            style={{ animationPlayState: index === currentSlide ? animationPlayState : 'paused' }}
                        >
                            {slide.title}
                        </h3>
                        <p
                            className="carousel-text"
                            style={{ animationPlayState: index === currentSlide ? animationPlayState : 'paused' }}
                        >
                            {slide.text}
                        </p>
                    </div>
                ))}
            </Slider>
        </div>
    );
}
