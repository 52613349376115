import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';

import Name from '../src/components/Name';
import Slogan from './components/Slogan';
import Slide from './components/Slide';
import DeleteAccountForm from './components/DeleteAccountForm';
import ConfirmAccountDeletion from './components/ConfirmAccountDeletion';

function App() {
  return (
    <Router>
      <div className='App'>
        <header className='header'>
          <Name />
          <Slogan />
        </header>

        <Routes>
          <Route exact path="/" element={
            <div className='main-content'>
              <div className='carousel-container'>
                <Slide />
              </div>

              <div className='app-buttons'>
                <a href='https://apps.apple.com/us/app/challoo/id1658834473' target='_blank' rel='noopener noreferrer'>
                  <img
                    src={require('./assets/appStoreButton.png')}
                    alt='Download on the App Store'
                    className='app-button'
                  />
                </a>
                <a href='https://play.google.com/store/apps/details?id=ch.challoo.Challoo' target='_blank' rel='noopener noreferrer'>
                  <img
                    src={require('./assets/googlePlayButton.png')}
                    alt='Get it on Google Play'
                    className='app-button'
                  />
                </a>
              </div>
            </div>
          } />
          <Route path="/delete-account" element={<DeleteAccountForm />} />
          <Route path="/confirm-deletion/:uid/:token" element={<ConfirmAccountDeletion />} />
        </Routes>

        <footer className='footer'>
          <span>&copy; 2023 Challoo</span>
        </footer>
      </div>
    </Router>
  );
}

export default App;
