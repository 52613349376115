export const slides = [
    { image: require('../assets/1.jpg'), title: 'Upload & Set Provision', text: 'Enter the royalty percentage to earn on each resale of your image. When your image changes hands, you earn royalties and gain a new connection.' },
    {
        image: require('../assets/2.jpg'), title: 'Set Your Image Price', text: "Determine your image's price.You can adjust it anytime, but note that changing the price will cancel all existing offers."
    },
    { image: require('../assets/3_1.jpg'), title: 'Select Top Offers', text: 'Explore and select the most appealing offers to forge new connections. Initiate a dialogue between yourself, the prospective buyer, and the original creator of the image.' },
    { image: require('../assets/3_2.jpg'), title: 'Opt for Prime Offers', text: 'Evaluate the offers at hand and choose the one that stands out, setting the stage for collaborative connections. This triggers a tripartite chat with you, the image buyer, and the creator.' },
    {
        image: require('../assets/3_3.jpg'), title: 'Identify the Best Deals', text: "Sift through the offers to identify the most advantageous one, paving the way for meaningful interactions. This initiates a three-way conversation involving you, the buyer, and the image's creator."
    },
    { image: require('../assets/3_4.jpg'), title: 'Forge Exclusive Connections', text: 'Place a bid on an image to initiate a unique connection. Once your offer is selected, it creates an exclusive communication channel between the buyer, seller, and image creator.' },
    { image: require('../assets/4_1.jpg'), title: 'Royalties and Networking', text: 'Reap the benefits of your creativity by earning royalties on each sale, while simultaneously expanding your network every time your image finds a new owner.' },
    { image: require('../assets/4_2.jpg'), title: 'Profit and Connect', text: 'Collect your deserved royalties with every transaction and broaden your professional circle as your image continues its journey through successive resales.' },
    { image: require('../assets/5_1.jpg'), title: 'Elevate Your Profile and Connections', text: 'Boost your professional presence and expand your network. Keep an eye on your performance metrics and engage with the community by participating in image auctions.' },
    { image: require('../assets/5_2.jpg'), title: 'Strengthen Your Presence and Network', text: "Amplify your profile's impact and widen your networking circle. Monitor your statistical progress and forge new connections by bidding on diverse images." },
];

