import React, { useState } from 'react';
import { IP } from '../constants/network';

const DeleteAccountForm = () => {
    const [email, setEmail] = useState('');
    const [hover, setHover] = useState(false);
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${IP}api/users/request_account_deletion/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: email }),
            });
            const data = await response.json();
            setMessage(data.message || 'An error occurred. Please try again.');
        } catch (error) {
            setMessage('An error occurred. Please try again.');
        }
    };

    return (
        <form onSubmit={handleSubmit} style={formStyles.formContainer}>
            <div style={formStyles.instructions}>
                <p>Please enter the email address associated with your account. After submitting this form, you will receive an email with a link to confirm the deletion of your account. This is to ensure the security of your account.</p>
            </div>
            <label htmlFor="email" style={formStyles.label}>Email Address:</label>
            <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                style={formStyles.input}
            />
            <button
                type="submit"
                style={hover ? { ...formStyles.button, ...formStyles.buttonHover } : formStyles.button}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                Submit
            </button>
            <p>{message}</p>
        </form>
    );
};

export default DeleteAccountForm;


const formStyles = {
    formContainer: {
        maxWidth: '400px',
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        boxSizing: 'border-box', // Ensures padding is included in width
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        fontWeight: 'bold',
    },
    input: {
        width: 'calc(100% - 16px)', // Adjust for padding
        padding: '8px',
        marginBottom: '20px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxSizing: 'border-box', // Include padding and border in width
    },
    button: {
        width: '100%',
        padding: '10px',
        border: 'none',
        borderRadius: '4px',
        backgroundColor: '#333', // Dark gray for the default state
        color: 'white',
        fontSize: '16px',
        cursor: 'pointer',
        boxSizing: 'border-box',
        transition: 'background-color 0.3s ease', // Smooth transition for hover effect
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
    },
    buttonHover: {
        backgroundColor: '#000', // Pure black for the hover state
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', // Slightly larger shadow on hover for a "lifting" effect
    },
    instructions: {
        marginBottom: '20px',
        padding: '10px',
        backgroundColor: '#eef2f7',
        borderRadius: '4px',
        color: '#333',
        fontSize: '14px',
    },
};
